<div *ngIf="!loader">
  <div class="img-wrapper">
   
    <div class="lable-block">
       <a [routerLink]="['/shop/product/no/sidebar/', product?.title.replace(' ', '-')]">
     <!-- <a  (click)="navigateTo(product.id)">     -->
        <h3 style="text-transform:none;color:black">{{ product?.nom | titlecase }}</h3>
        <h4 class="product-price" style="min-height: 24px;">
          <span class="product-desc" >{{ product?.descripciocurta }}</span>        
        </h4>   
        <!-- <span class="lable3" *ngIf="product.id==1">Nuevo</span> -->
        <span class="lable5" >{{ product?.preu }} €</span>
      </a>  
    </div>
    <div class="front">
      <a [routerLink]="['/shop/product/no/sidebar/', product.title.replace(' ', '-')]"> 
      
      <!-- <a  (click)="navigateTo(product.id)" class="overlay-background" >    -->
        <div class="overlay-background">
        <img 
          [defaultImage]="product.imatge ? path+'/'+product.imatge : 'assets/images/product/placeholder.jpg'" 
          [lazyLoad]="product.imatge ? path+'/'+product.imatge : 'assets/images/product/placeholder.jpg' " 
          
           />
          </div>
       </a> 
   
    </div>
    
   
    <div class="cart-info cart-wrap">
      <!-- <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)"
        *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)"
        *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </a> -->
      
    </div>
  </div>
  <div class="product-detail">
    <div>
     
     <!--  <a [routerLink]="['/shop/product/no/sidebar/', product?.title.replace(' ', '-')]">
        <h3 style="text-transform:none">{{ product?.nom | titlecase }}</h3>
      </a>   -->
     
    
      
   

     
   
     
    </div>
  
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

