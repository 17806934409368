import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InstagramService {


  public baseUrl=environment.apiUrl;
  
  constructor(private http: HttpClient) {}

  // Method to get Instagram pictures
  getInstagramPhotos() {
    console.log("getimatges", "init");
  return this.http.post<any>(this.baseUrl + 'get_images_instagram.php', {  })
  .pipe(
    map(imagesResponse => {            
      // Update the BehaviorSubject with new data
 
    

      console.log("getimatges", imagesResponse);
   
      
      return imagesResponse;
    })
  );        
  }
}