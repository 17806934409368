import { Component, OnInit } from '@angular/core';
import { InstagramService } from '../../services/instagram.service';
import { ProductSlider } from '../../data/slider';

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss'],
})


export class InstagramComponent implements OnInit {
  public instagramPhotos: any[] = [];
  public errorMessage: string = '';
  public ProductSliderConfig = {
    loop: true,                // Enables looping
    autoplay: true,            // Enables autoplay
    autoplayTimeout: 3000,     // Time between slides (in ms)
    autoplayHoverPause: true,  // Pause on hover
    margin: 10,                // Space between slides
    nav: true,                 // Navigation buttons
    dots: false,               // Dots at the bottom for slide indication
    items: 3,                  // Number of items shown in one view
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 3
      }
    }
  };
  constructor(private instagramService: InstagramService) {

    
  }

  ngOnInit(): void {
    this.fetchInstagramPhotos();
  }

  fetchInstagramPhotos(): void {
    this.instagramService.getInstagramPhotos().subscribe(
      (response) => {
        this.instagramPhotos = response; // Instagram API returns an array in 'data'
        console.log("getimatges","response",this.instagramPhotos);
      },
      (error) => {
        this.errorMessage = 'Failed to load Instagram photos. Please try again later.';
        console.error(error);
      }
    );
  }
}
