<div class="main-navbar" >
  <div id="mainnav">
    <div class="toggle-nav" (click)="mainMenuToggle()">
      <i class="fa fa-bars sidebar-bar"></i>
    </div>
    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

      <li class="back-btn">
        <div class="mobile-back text-end" (click)="mainMenuToggle()">
          <span class="material-symbols-outlined" style="font-size: 36px;">
            close
            </span>
          
        </div>
      </li>

      <li *ngFor="let menuItem of menuItems" [class.mega-menu]="menuItem.megaMenu">
        <!-- Sub -->
        <a href="javascript:void(0)" class="nav-link" *ngIf="menuItem.type === 'sub'" 
            (click)="toggletNavActive(menuItem)" [ngClass]="{'sticky-color': isSticky}">
          {{ menuItem.title  }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText  }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        <!-- Link -->
        <a (click)="navigateTo(menuItem.path)"  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link"
          *ngIf="menuItem.type === 'link' " [ngClass]="{'sticky-color': isSticky}">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText  }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        <!-- External Link -->
        <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText  }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        <!-- External Tab Link -->
        <a href="{{ menuItem.path }}" class="nav-link" *ngIf="menuItem.type === 'extTabLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText  }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>

        <!-- 2nd Level Menu -->
        <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children">
          <li *ngFor="let childrenItem of menuItem.children">
            <!-- Sub -->
            <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" 
                (click)="toggletNavActive(childrenItem)">
            
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText  }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <!-- Link -->
            <a (click)="navigateTo(childrenItem.path)"  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
              *ngIf="childrenItem.type === 'link' ">
              {{ childrenItem.title  }} 
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText  }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <!-- External Link -->
            <a href="{{ childrenItem.path }}"
              *ngIf="childrenItem.type === 'extLink' ">
              {{ childrenItem.title  }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText  }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <!-- External Tab Link -->
            <a href="{{ childrenItem.path }}" target="_blank"
              *ngIf="childrenItem.type === 'extTabLink' ">
              {{ childrenItem.title  }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText  }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>

            <!-- 3rd Level Menu -->
            <ul *ngIf="childrenItem.children" [class.opensubchild]="childrenItem.active" class="nav-sub-childmenu">
              <li *ngFor="let childrenSubItem of childrenItem.children">
                <!-- Link -->
                <a [routerLink]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
                  *ngIf="childrenSubItem.type === 'link' ">
                  {{ childrenSubItem.title  }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText  }}</span>
                </a>
                <!-- External Link -->
                <a href="{{ childrenSubItem.path }}"
                  *ngIf="childrenSubItem.type === 'extLink' ">
                  {{ childrenSubItem.title  }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText  }}</span>
                </a>
                <!-- External Tab Link -->
                <a href="{{ childrenSubItem.path }}" target="_blank"
                  *ngIf="childrenSubItem.type === 'extTabLink' ">
                  {{ childrenSubItem.title  }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText  }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>

        <div class="mega-menu-container" *ngIf="menuItem.megaMenu && menuItem.children">
          <div class="container">
            <div class="row">
              <div class="col mega-box" *ngFor="let childrenItem of menuItem.children">
                <div class="link-section">
                  <div class="menu-title">
                    <h5>
                        {{ childrenItem.title  }}
                        <span class="sub-arrow" *ngIf="childrenItem.children"></span>
                    </h5>
                  </div>
                  <div class="menu-content opensubmegamenu">
                    <ul *ngIf="childrenItem.children">
                      <li *ngFor="let childrenSubItem of childrenItem.children">
                        <!-- Sub -->
                          <a href="javascript:void(0)" *ngIf="childrenSubItem.type === 'sub'">
                            {{ childrenSubItem.title  }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText  }}</span>
                          </a>
                          <!-- Link -->
                          <a [routerLink]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
                            *ngIf="childrenSubItem.type === 'link' ">
                            {{ childrenSubItem.title  }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText  }}</span>
                          </a>
                          <!-- External Link -->
                          <a href="{{ childrenSubItem.path }}"
                            *ngIf="childrenSubItem.type === 'extLink' ">
                            {{ childrenSubItem.title  }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText  }}</span>
                          </a>
                          <!-- External Tab Link -->
                          <a href="{{ childrenSubItem.path }}" target="_blank"
                            *ngIf="childrenSubItem.type === 'extTabLink' ">
                            {{ childrenSubItem.title  }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText  }}</span>
                          </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
