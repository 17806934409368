import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable,throwError } from 'rxjs';
import { map, startWith, delay,catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../classes/product';
import { shareReplay } from 'rxjs/operators';
import { DniRecord } from '../classes/dnirecord';
const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cart: JSON.parse(localStorage['cartItems'] || '[]')
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  public Currency = { name: 'EUR', currency: 'EUR', price: 1 } // Default Currency
  public OpenCart: boolean = false;
  public urlApi:string="https://oliart.es/api/";
  public Products
 
  private cartItemsSubject = new BehaviorSubject<any[]>(state.cart);
  cartItems$ = this.cartItemsSubject.asObservable();

  constructor(private http: HttpClient,
    private toastrService: ToastrService) {
      //console.log("producto","constructor");
      
     }

     public getApi():string{
      return this.urlApi;
     }

     
  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

  // Product

  private products$: Observable<Product[]>;

  private get products(): Observable<Product[]> {
    if (!this.products$) {
     // console.log("productos", "carga de productos");
      this.products$ = this.GetProductos().pipe(
        map(next => {
          localStorage['products'] = JSON.stringify(next);
          return next;
        }),
        startWith(JSON.parse(localStorage['products'] || '[]')),
        shareReplay(1) // Cache the latest emitted value and share it with future subscribers
      );
    }
    else{
     // console.log("productos", "no hay");
    }
    return this.products$;
  }
  
  public GetProductos(): Observable<Product[]> {            
   // console.log("productos", "llamada api");         
    return this.http.post<Product[]>(this.urlApi + 'getlistaproductosnew.php', {})
      .pipe(
        map(documentos => {
       //   console.log("documentos", documentos);
          return documentos;
        })
      );        
  }


  public ConfirmarPedido( pedido :string) {
      
    return this.http.post<any>(this.urlApi + 'confirmar_pedido.php',{ params: pedido})
    .pipe(map(
        res=>{
            //console.log(res);
        })
    );        
 }
  

 public RevisarSiPagado( pedido :string) {
      
  return this.http.post<any>(this.urlApi + 'revisar_pago_pagado.php',{ params: pedido})
  .pipe(map(
      res=>{
         // console.log(res);
          return res;
      })
  );        
}
  

  // Get Products
  public get getProducts(): Observable<Product[]> {
   // console.log("producto","get productos");
    return this.products;
  }

  // Get Products By Slug
 /*  public getProductBySlug(slug: string): Observable<Product> {
    console.log("productos",slug,"t2");
    return this.products.pipe(map(items => { 
      return items.find((item: any) => { 
        return item.title.replace(' ', '-') === slug; 
      }); 
    }));
  } */
  getAllDnis(): Observable<DniRecord[]> {
    return this.http.post<any>(this.urlApi + 'getalldnis.php',{ })
    .pipe(map(dnispares => {            
            //console.log("dnispares",dnispares);

    return dnispares;
    }));    
  }
  public GetListaCursos() {                     
    return this.http.post<any>(this.urlApi + 'getlistacursosget.php',{ })
    .pipe(map(sociedades => {            
            

    return sociedades;
    }));        
 }

  public getProductBySlug(slug: string): Observable<Product> {
  //  console.log("productos",  "t1",slug);
    return this.products.pipe(
      map(items => {
   //     console.log("productos",  "t2",items);
        const product = items.find((item: any) => 
          item.title.replace(' ', '-') === slug
        );
        if (!product) {
          throw new Error('Product not found');
        }
        return product;
      }),
      catchError(err => throwError(err)) // Propagate error if no product is found
    );
  }


  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Wishlist
  public addToWishlist(product): any {
    const wishlistItem = state.wishlist.find(item => item.id === product.id)
    if (!wishlistItem) {
      state.wishlist.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in wishlist.');
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find(item => item.id === product.id)
    if (!compareItem) {
      state.compare.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in compare.');
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.cart);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Cart
  /* public addToCart(product): any {
    console.log("producto","añadir",product);
    const cartItem = state.cart.find(item => item.id === product.id);
    const qty = product.quantity ? product.quantity : 1;
    const items = cartItem ? cartItem : product;
    const stock = this.calculateStockCounts(items, qty);
    
    if(!stock) return false

    if (cartItem) {
        cartItem.quantity += qty    
    } else {
      state.cart.push({
        ...product,
        quantity: qty
      })
    }

    this.OpenCart = true; // If we use cart variation modal
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true;
  } */


  public async addToCart(product: any): Promise<{ success: boolean, message: string }> {
   // console.log("producto", "añadir", product);
  
    const cartItem = state.cart.find(item => item.id === product.id);
  
    // If the product is already in the cart, return a failure response
    if (cartItem) {
      return { success: false, message: "L'activitat ja es troba dins la cistella. Només es pot comprar una vegada. " };
    }
  
    // Check stock
    const qty = 1;
    const stock = this.calculateStockCounts(product, qty);
  
    if (!stock) {
      return { success: false, message: "Product is out of stock." };
    }
  
    // Add product to the cart
    state.cart.push({
      ...product,
      quantity: qty
    });
  
    this.OpenCart = true;
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
  
    return { success: true, message: "Product added to the cart successfully." };
  }


  // Update Cart Quantity
  public updateCartQuantity(product: Product, quantity: number): Product | boolean {
    return state.cart.find((items, index) => {
      if (items.id === product.id) {
        const qty = state.cart[index].quantity + quantity
        const stock = this.calculateStockCounts(state.cart[index], quantity)
        if (qty !== 0 && stock) {
          state.cart[index].quantity = qty
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true
      }
    })
  }

 


    // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity
    const stock = product.stock
    if (stock < qty || stock == 0) {
      this.toastrService.error('You can not add more items than available. In stock '+ stock +' items.');
      return false
    }
    return true
  }

  // Remove Cart items
  public removeCartItem(product: Product): any {
    const index = state.cart.indexOf(product);
    state.cart.splice(index, 1);
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true
  }



  getFormattedPrice(precio:string) {
    if (!precio) return null;
    // Replace period with nothing (remove it), and replace comma with dot
    const normalizedPrice = precio.replace(/\./g, '').replace(',', '.');
    return parseFloat(normalizedPrice);
  }




  public cartTotalAmount(usePreuAmipa: boolean = false, currencyConversionRate: number = 1): Observable<number> {
    return this.cartItems.pipe(
      map((products: Product[]) => {
        
        return products.reduce((prev, curr: Product) => {
          // Use preu or preuAmipa depending on the usePreuAmipa parameter

                                 
       
          let price = usePreuAmipa && curr.preuamipa ? this.getFormattedPrice(curr.preuamipa) : this.getFormattedPrice(curr.preu);
  
          // Return the accumulated total with quantity and currency conversion applied
          return prev + (price * curr.quantity );
        }, 0);
      })
    );
  }
  

  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    return this.products.pipe(map(product => 
      product.filter((item: Product) => {
        if (!filter.length) return true
        const Tags = filter.some((prev) => { // Match Tags
          if (item.tags) {
            if (item.tags.includes(prev)) {
              return prev
            }
          }
        })
        return Tags
      })
    ));
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {

    if(payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      })
    } 
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) { 
      currentPage = 1; 
    } else if (currentPage > totalPages) { 
      currentPage = totalPages; 
    }
    
    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if(currentPage < paginateRange - 1){
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage =  currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }


  clearCart(): void {   
    state.cart.splice(0, state.cart.length);

    // Update localStorage to reflect the empty cart
    localStorage.setItem('cartItems', JSON.stringify(state.cart));

    // Notify subscribers about the cart update
    this.cartItemsSubject.next(state.cart);
  }

}
