    <div class="instagram" style="padding: 0;padding-top: 20px" *ngIf="instagramPhotos.length">
      <div class="container">
       
     
            <owl-carousel-o class="product-m no-arrow" [options]="ProductSliderConfig">
              <ng-container *ngFor="let photo of instagramPhotos| slice:0:16">
                <ng-template carouselSlide>
                  <div class="product-box">
                    <a href="https://www.instagram.com/oliart.es/" target="_blank">
                      <img [src]="photo" alt="Instagram Photo" />
                    </a>
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
       
     
      </div>
    </div>

