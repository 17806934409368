import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logoblanco2.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;
  isHomePage: boolean = false;
  public currentUrl:string="i";

  constructor(private router: Router) {
   
   }

  ngOnInit(): void {
    this.isHomePage = this.router.url === '/' || this.router.url === '/home';
    if(! this.isHomePage){
      this.themeLogo="assets/images/icon/logo.png";
    }
    


  }
  ngDoCheck(): void {
    // Check again in case of route changes
    this.isHomePage = this.router.url === '/' || this.router.url === '/home';
    if(! this.isHomePage){
      this.themeLogo="assets/images/icon/logo.png";
    }
  }
  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  
    if ((number >= 150 && window.innerWidth > 400)) { 
  	  this.stick = true;
      this.themeLogo="assets/images/icon/logo.png";
  	} else {
  	  this.stick = false;
      this.themeLogo="assets/images/icon/logoblanco2.png";
  	}
  }

}
