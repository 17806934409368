import { Component, HostListener, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems: Menu[];

  public isSticky: boolean = false;

  constructor(private router: Router, public navServices: NavService) {
    
    this.navServices.getItems().subscribe(items => {
      this.menuItems = items;
      console.log("Updated menu items", this.menuItems);
    });

    // Optionally call GetMenu if you want to fetch/update the menu on initialization
    this.navServices.GetMenu().subscribe(items =>{
      this.menuItems=items;
    });

    this.router.events.subscribe((event) => {
    
      this.navServices.mainMenuToggle = false;
    });

  }

  ngOnInit(): void {
  
    if(! (this.router.url === '/' || this.router.url === '/home')){
      this.isSticky = true;
    }
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }


  @HostListener('window:scroll', [])
  onWindowScroll() {
    const header = document.querySelector('header'); // Assuming 'header' is the element with .sticky and .fixed
    if (header?.classList.contains('sticky') && header?.classList.contains('fixed')) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }
  


  navigateTo(path: string) {
    // Scroll to top before navigating
    console.log("click");
    window.scrollTo(0, 0);

    // Use Router to navigate to the path
    // First, navigate to a dummy route (such as `/`), skipping the location change
  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    // Then, navigate to the actual path
    this.router.navigate([path]);
  });
  }


}
