<!-- <header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky"> -->
  <header class="sticky" [ngClass]="class" [class.fixed]="(stick && sticky) || !isHomePage">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
       
            <div class="brand-logo">
              <a routerLink="/home/" id="headerlogo">
                <div class="logo-frame">
                <img [src]="themeLogo" class="imagenlogo" alt="logo">
               
              </div>
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
               <app-menu></app-menu> 
            </div>
            <div>
            <app-settings></app-settings> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

